export enum ClientOptions {
    AlphaC = 'ABI',
    Neptune = 'NESTLE',
}

// hack to that client names don't appear in the route

export const clientIdMap = {
    [ClientOptions.AlphaC]: 'b5a43c2b-8f3a-4778-8ef4-eb83d8968b28',
    [ClientOptions.Neptune]: 'e7c9f220-5ca6-4e7c-9d1c-d5b5f5b8c2eb',
};

export const clientIdReverseMap = {
    'b5a43c2b-8f3a-4778-8ef4-eb83d8968b28': ClientOptions.AlphaC,
    'e7c9f220-5ca6-4e7c-9d1c-d5b5f5b8c2eb': ClientOptions.Neptune,
};

export enum CountryOptions {
    Ecuador = 'EC',
    Phillipines = 'PH',
    Mexico = 'MX',
}

export type Environment = 'alpha' | 'dev' | 'prod';
