import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import cx from 'classnames';
import { useClientTranslation } from '../../../hooks/helm/useClientTranslation';

interface PoweredByArenaOverlayProps {
    className?: string;
}

export const PoweredByArenaOverlay: FC<PoweredByArenaOverlayProps> = ({ className }) => {
    const { t } = useClientTranslation();
    return (
        <div className={cx('flex items-center', className)}>
            <div>
                <Link href="https://arena-ai.com" target="_blank">
                    <div className="flex items-center justify-center space-x-2">
                        <div className="text-[10px]">{t('powered by')}:</div>
                        <Image
                            width={72}
                            height={14}
                            alt="arenaLogo"
                            src="/images/arenaLogoFullBlackSmall.svg"
                        />
                    </div>
                </Link>
            </div>
        </div>
    );
};
