/* eslint-disable @next/next/no-img-element */
import { Button, Input, PasswordInput } from '@mantine/core';
import Head from 'next/head';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useAppContext } from '../contexts/appContext';
import { PoweredByArenaOverlay } from './helm/components/poweredByArenaOverlay';
import Text from '../andromeda/text';

const commonStyling: React.CSSProperties = {
    textAlign: 'center',
};

const Login = () => {
    const { googleLogin, microsoftLogin, credentialLogin } = useAppContext();
    const [formError, setFormError] = useState<string>();
    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const { username, password } = e.currentTarget as typeof e.currentTarget & {
                username: { value: string };
                password: { value: string };
            };
            const res = await credentialLogin({
                username: username.value,
                password: password.value,
            });
            if (res === 'You do not have permission to perform this action.') {
                setFormError('Invalid Credentials');
                username.value = '';
                password.value = '';
            }
        },
        [credentialLogin],
    );

    return (
        <div
            style={{
                background: 'linear-gradient(113deg, #EDF2F6 0%, #FDFDFD 100%)',
            }}
            className="flex h-screen flex-col items-center justify-center"
        >
            <Head>
                <title>Arena</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            <div className="flex flex-row items-center justify-center">
                <img
                    alt="arena"
                    src="images/BEES.ai_wordmark.png"
                    style={{
                        height: '42px',
                        marginBottom: '10px',
                        width: 'auto',
                    }}
                />
            </div>
            <div className="mb-3 flex gap-2 border-b-1 border-border-color p-3">
                <Button
                    type="button"
                    variant="default"
                    data-testid="login-google"
                    className="!text-navy-70 !border-navy-15 !bg-transparent !font-medium hover:!text-navy"
                    onClick={() => {
                        googleLogin();
                    }}
                >
                    Google Login
                </Button>
                <Button
                    type="button"
                    variant="default"
                    data-testid="login-microsoft"
                    className="!text-navy-70 !border-navy-15 !bg-transparent !font-medium hover:!text-navy"
                    onClick={() => {
                        microsoftLogin();
                    }}
                >
                    Microsoft Login
                </Button>
            </div>
            <div>
                <form onSubmit={handleSubmit} className="flex w-full min-w-[275px] flex-col gap-2">
                    <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        classNames={{
                            input: '!border-navy-15 focus-within:!border-secondary !bg-navy-5 !text-navy-70 placeholder:!text-navy-70 !font-medium hover:!border-navy-30',
                        }}
                        styles={{
                            input: {
                                ...commonStyling,
                                paddingRight: '26px',
                            },
                        }}
                    />
                    <PasswordInput
                        name="password"
                        placeholder="Password"
                        id="password"
                        classNames={{
                            input: '!border-navy-15 !bg-transparent focus-within:!border-secondary !text-center !font-medium !pr-[26px] hover:!border-navy-30',
                            section: '!w-8 !h-9',
                            innerInput:
                                '!bg-navy-5 !text-navy-70 placeholder:!text-navy-70 !font-medium !text-center !pr-[26px]',
                        }}
                        styles={{
                            innerInput: { ...commonStyling },
                        }}
                    />
                    <Button
                        className="!text-navy-70 !border-navy-15 !bg-transparent !font-medium hover:!text-navy"
                        type="submit"
                        variant="default"
                    >
                        Login
                    </Button>
                    <Text
                        type="Button/regular"
                        className={classNames('text-red-600 text-center', {
                            hidden: !formError,
                        })}
                    >
                        {formError}
                    </Text>
                </form>
            </div>
            <PoweredByArenaOverlay className="fixed bottom-8 right-20 text-black" />
        </div>
    );
};

export default Login;
