import { create } from 'zustand';
import { ClientOptions, CountryOptions } from '../../util/config';

interface State {
    client: ClientOptions;
    setClient: (client: ClientOptions) => void;
    demoMode: boolean;
    setDemoMode: (newMode: boolean) => void;
    forceShowRealSkuNames: boolean;
    setForceShowRealSkuNames: (newMode: boolean) => void;
    showCreatedBy: boolean;
    setShowCreatedBy: (newMode: boolean) => void;
    country: CountryOptions;
    setCountry: (country: CountryOptions) => void;
}

const useStore = create<State>()((set, get) => ({
    client: null,
    setClient: (client: ClientOptions) => set({ client }),
    country: CountryOptions.Ecuador,
    setCountry: (country: CountryOptions) => set({ country }),
    demoMode: false,
    setDemoMode: (demoMode: boolean) => set({ demoMode }),
    forceShowRealSkuNames: false,
    setForceShowRealSkuNames: (forceShowRealSkuNames: boolean) => set({ forceShowRealSkuNames }),
    showCreatedBy: false,
    setShowCreatedBy: (showCreatedBy: boolean) => set({ showCreatedBy }),
}));

export default useStore;
