import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import useStore from '../../components/helm/components/state/state';
import { ClientOptions, CountryOptions } from '../../components/helm/util/config';

const useCommonCopy = () => {
    const { t } = useTranslation();
    const demoModeMapping = useMemo(
        () => ({
            poc: t('store'),
            beesForce: 'Tasking App',
            customer: 'E-commerce Platform',
            customerApp: 'E-commerce Platform',
            bees: 'the E-commerce Platform',
            beesCustomer: 'E-commerce Platform',
            beesCustomerApp: 'E-commerce Platform',
            force: 'Tasking App',
            beesGrow: 'Phone Sales App',
            grow: 'Phone Sales App',
            clubB: 'Loyalty',
            beesDigitalAdoption: 'the E-commerce Platform',

            'Suggested Order': 'Frequencly Ordered',
            Upsell: 'New Products',
            'Forgotten Items': 'Checkout Upsell',
            'Top Deals': 'Personalized Discounts',
            Promos: 'Automated Pricing',
            Challenge: 'Gamification',
            'Deal Sort': 'Personalized Promo',
        }),
        [t],
    );

    const productionModeMapping = useMemo(
        () => ({
            poc: t('POC'),
            beesForce: 'BEES Force',
            customer: 'Customer',
            customerApp: 'Customer App',
            bees: 'BEES',
            beesCustomerApp: 'BEES Customer App',
            beesCustomer: 'BEES Customer',
            force: 'Force',
            beesGrow: 'BEES Grow',
            grow: 'Grow',
            clubB: 'Club B',
            beesDigitalAdoption: 'BEES',
            'Suggested Order': 'Suggested Order',
            Upsell: 'Upsell',
            'Forgotten Items': 'Forgotten Items',
            'Top Deals': 'Top Deals',
            Promos: 'Promos',
            Challenge: 'Challenge',
            'Deal Sort': 'Deal Sort',
            // Add new mappings here as you need
        }),
        [t],
    );

    const demoMode = useStore(state => state.demoMode);
    return useMemo(() => {
        const modeMapping = demoMode ? demoModeMapping : productionModeMapping;
        return Object.keys(modeMapping).reduce(
            (acc, key) => {
                acc[key] = demoMode ? modeMapping[key] : modeMapping[key];
                return acc;
            },
            { salesperson: t('BDR') },
        ); // static mappings can be added directly here
    }, [demoMode, demoModeMapping, productionModeMapping, t]);
};
const createClientCopyMap = (commonCopy: any) => ({
    [ClientOptions.AlphaC]: {
        [CountryOptions.Ecuador]: { ...commonCopy },
        [CountryOptions.Phillipines]: { ...commonCopy },
    },
    [ClientOptions.Neptune]: {
        [CountryOptions.Ecuador]: { ...commonCopy },
        [CountryOptions.Phillipines]: { ...commonCopy },
    },
});

export const useClientTranslation = () => {
    const { t: originalT, ...rest } = useTranslation();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);

    const commonCopy = useCommonCopy();
    const copyMap = createClientCopyMap(commonCopy);

    const clientCopy = useMemo(
        () => (client ? copyMap[client][country] : {}),
        [client, copyMap, country],
    );
    const t: any = (...args: Parameters<typeof originalT>): ReturnType<typeof originalT> => {
        // todo: type
        const [key, options] = args;
        return originalT(key, { ...(options as object), ...clientCopy }) as unknown as string;
    };
    return { t, ...rest, copyMap: copyMap[client] ? copyMap[client][country] : {} };
};
